var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"cols":"21"}},[_c('b-media',{scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{ref:"previewEl",staticClass:"margin-top-5 ml-1",attrs:{"src":_vm.employee.avatar,"size":"115px"}})]},proxy:true}])},[_c('h1',{staticClass:"ml-1 mt-1 font-weight-bolder text-primary",staticStyle:{"font-family":"\"Kalam\", cursive"}},[_vm._v(" Hello, ")]),_c('span',{staticClass:"ml-1 font-weight-bolder",staticStyle:{"font-size":"18px"}},[_vm._v(_vm._s(_vm.employee.name)+" 👋")])])],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-card',{staticClass:"card-apply-job",attrs:{"no-body":""}},[_c('b-card-body',[_c('span',{staticClass:"font-weight-bolder",staticStyle:{"font-size":"18px"}},[_vm._v("Shortcuts")])])],1)],1)],1),_c('b-row',[(_vm.getPermission('view_frontend_timesheet'))?_c('b-col',[_c('b-card',{staticClass:"card-apply-job",attrs:{"no-body":""},on:{"click":function($event){_vm.goTo(
                        'https://timesheet.' +
                            _vm.getPath() +
                            '/attendance/my-attendance/' +
                            (new Date().getMonth() + 1) +
                            '/' +
                            new Date().getFullYear() +
                            '/'
                    )}}},[_c('b-card-body',{staticStyle:{"cursor":"pointer"}},[_c('b-avatar',{attrs:{"variant":"light-primary","size":"70px"}},[_c('feather-icon',{attrs:{"icon":"ClockIcon","size":"25"}})],1),_c('span',{staticClass:"ml-1",staticStyle:{"font-size":"18px"}},[_vm._v("Log working hours")])],1)],1)],1):_vm._e(),(_vm.getPermission('view_expenses_frontend'))?_c('b-col',[_c('b-card',{staticClass:"card-apply-job",attrs:{"no-body":""},on:{"click":function($event){return _vm.$router.push({ name: 'forms-expenses' })}}},[_c('b-card-body',{staticStyle:{"cursor":"pointer"}},[_c('b-avatar',{attrs:{"variant":"light-success","size":"70px"}},[_c('feather-icon',{attrs:{"icon":"DollarSignIcon","size":"25"}})],1),_c('span',{staticClass:"ml-1",staticStyle:{"font-size":"18px"}},[_vm._v("Submit expense claims")])],1)],1)],1):_vm._e(),(_vm.getPermission('view_mileage_frontend'))?_c('b-col',[_c('b-card',{staticClass:"card-apply-job",attrs:{"no-body":""},on:{"click":function($event){return _vm.$router.push({ name: 'forms-mileage' })}}},[_c('b-card-body',{staticStyle:{"cursor":"pointer"}},[_c('b-avatar',{attrs:{"variant":"light-secondary","size":"70px"}},[_c('feather-icon',{attrs:{"icon":"MapIcon","size":"25"}})],1),_c('span',{staticClass:"ml-1",staticStyle:{"font-size":"18px"}},[_vm._v("Submit mileage claims")])],1)],1)],1):_vm._e(),(_vm.getPermission('approve_timesheet'))?_c('b-col',[_c('b-card',{staticClass:"card-apply-job",attrs:{"no-body":""},on:{"click":function($event){_vm.goTo(
                        'https://timesheet.' +
                            _vm.getPath() +
                            '/attendance/peoples-attendance/'
                    )}}},[_c('b-card-body',{staticStyle:{"cursor":"pointer"}},[_c('b-avatar',{attrs:{"variant":"light-primary","size":"70px"}},[_c('feather-icon',{attrs:{"icon":"ClockIcon","size":"25"}})],1),_c('span',{staticClass:"ml-1",staticStyle:{"font-size":"18px"}},[_vm._v("Review timesheets")])],1)],1)],1):_vm._e(),(_vm.getPermission('approve_expenses'))?_c('b-col',[_c('b-card',{staticClass:"card-apply-job",attrs:{"no-body":""},on:{"click":function($event){return _vm.$router.push({ name: 'forms-peoples-expenses' })}}},[_c('b-card-body',{staticStyle:{"cursor":"pointer"}},[_c('b-avatar',{attrs:{"variant":"light-success","size":"70px"}},[_c('feather-icon',{attrs:{"icon":"DollarSignIcon","size":"25"}})],1),_c('span',{staticClass:"ml-1",staticStyle:{"font-size":"18px"}},[_vm._v("Review expenses")])],1)],1)],1):_vm._e(),(_vm.getPermission('approve_mileage'))?_c('b-col',[_c('b-card',{staticClass:"card-apply-job",attrs:{"no-body":""},on:{"click":function($event){return _vm.$router.push({ name: 'forms-peoples-mileage' })}}},[_c('b-card-body',{staticStyle:{"cursor":"pointer"}},[_c('b-avatar',{attrs:{"variant":"light-secondary","size":"70px"}},[_c('feather-icon',{attrs:{"icon":"MapIcon","size":"25"}})],1),_c('span',{staticClass:"ml-1",staticStyle:{"font-size":"18px"}},[_vm._v("Review mileages")])],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }