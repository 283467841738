<template>
    <div>
        <b-card>
            <b-row>
                <b-col cols="21">
                    <b-media>
                        <template #aside>
                            <b-avatar
                                ref="previewEl"
                                class="margin-top-5 ml-1"
                                :src="employee.avatar"
                                size="115px"
                            />
                        </template>
                        <h1
                            class="ml-1 mt-1 font-weight-bolder text-primary"
                            style="font-family: &quot;Kalam&quot;, cursive"
                        >
                            Hello,
                        </h1>
                        <span
                            class="ml-1 font-weight-bolder"
                            style="font-size: 18px"
                            >{{ employee.name }} 👋</span
                        >
                    </b-media>
                </b-col>
            </b-row>
        </b-card>
        <b-row>
            <b-col>
                <b-card no-body class="card-apply-job">
                    <b-card-body>
                        <span class="font-weight-bolder" style="font-size: 18px"
                            >Shortcuts</span
                        >
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col v-if="getPermission('view_frontend_timesheet')">
                <b-card
                    no-body
                    class="card-apply-job"
                    @click="
                        goTo(
                            'https://timesheet.' +
                                getPath() +
                                '/attendance/my-attendance/' +
                                (new Date().getMonth() + 1) +
                                '/' +
                                new Date().getFullYear() +
                                '/'
                        )
                    "
                >
                    <b-card-body style="cursor: pointer">
                        <b-avatar variant="light-primary" size="70px">
                            <feather-icon icon="ClockIcon" size="25" />
                        </b-avatar>
                        <span class="ml-1" style="font-size: 18px"
                            >Log working hours</span
                        >
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col v-if="getPermission('view_expenses_frontend')">
                <b-card
                    no-body
                    class="card-apply-job"
                    @click="$router.push({ name: 'forms-expenses' })"
                >
                    <b-card-body style="cursor: pointer">
                        <b-avatar variant="light-success" size="70px">
                            <feather-icon icon="DollarSignIcon" size="25" />
                        </b-avatar>
                        <span class="ml-1" style="font-size: 18px"
                            >Submit expense claims</span
                        >
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col v-if="getPermission('view_mileage_frontend')">
                <b-card
                    no-body
                    class="card-apply-job"
                    @click="$router.push({ name: 'forms-mileage' })"
                >
                    <b-card-body style="cursor: pointer">
                        <b-avatar variant="light-secondary" size="70px">
                            <feather-icon icon="MapIcon" size="25" />
                        </b-avatar>
                        <span class="ml-1" style="font-size: 18px"
                            >Submit mileage claims</span
                        >
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col v-if="getPermission('approve_timesheet')">
                <b-card
                    no-body
                    class="card-apply-job"
                    @click="
                        goTo(
                            'https://timesheet.' +
                                getPath() +
                                '/attendance/peoples-attendance/'
                        )
                    "
                >
                    <b-card-body style="cursor: pointer">
                        <b-avatar variant="light-primary" size="70px">
                            <feather-icon icon="ClockIcon" size="25" />
                        </b-avatar>
                        <span class="ml-1" style="font-size: 18px"
                            >Review timesheets</span
                        >
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col v-if="getPermission('approve_expenses')">
                <b-card
                    no-body
                    class="card-apply-job"
                    @click="$router.push({ name: 'forms-peoples-expenses' })"
                >
                    <b-card-body style="cursor: pointer">
                        <b-avatar variant="light-success" size="70px">
                            <feather-icon icon="DollarSignIcon" size="25" />
                        </b-avatar>
                        <span class="ml-1" style="font-size: 18px"
                            >Review expenses</span
                        >
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col v-if="getPermission('approve_mileage')">
                <b-card
                    no-body
                    class="card-apply-job"
                    @click="$router.push({ name: 'forms-peoples-mileage' })"
                >
                    <b-card-body style="cursor: pointer">
                        <b-avatar variant="light-secondary" size="70px">
                            <feather-icon icon="MapIcon" size="25" />
                        </b-avatar>
                        <span class="ml-1" style="font-size: 18px"
                            >Review mileages</span
                        >
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BTabs,
    BTab,
    BAlert,
    BFormFile,
    VBTooltip,
    BCardBody,
} from "bootstrap-vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import Auth from "@/api/services/Auth"
import Ripple from "vue-ripple-directive"
import os from "os"

export default {
    name: "Home",
    components: {
        BButton,
        BMedia,
        BAvatar,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BForm,
        BTable,
        BCard,
        BCardHeader,
        BCardTitle,
        BFormCheckbox,
        BTabs,
        BTab,
        BAlert,
        BFormFile,
        ToastificationContent,
        BCardBody,
    },
    directives: {
        Ripple,
        "b-tooltip": VBTooltip,
    },
    data() {
        return {
            employee: {},
            reportsTo: {},
        }
    },
    async created() {
        this.$loading(true)
        try {
            const response = await Auth.employee()
            this.employee = response?.data
        } catch (error) {
            console.log(error)
        } finally {
            this.$loading(false)
        }
    },
    methods: {
        goTo(url) {
            window.location.href = url
        },
        getPath() {
            return os.hostname().includes("damia")
                ? "damiagroup.pt"
                : "wearemeta.io"
        },
        getPermission(permission) {
            let valid = false
            this.$store.state.portal.profile.groups?.forEach((element) => {
                element.permissions.forEach((item) => {
                    if (item.codename === permission) {
                        valid = true
                    }
                })
            })
            this.$store.state.portal.profile.user_permissions?.forEach(
                (element) => {
                    if (element.codename === permission) {
                        valid = true
                    }
                }
            )
            return valid
        },
    },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
